<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Department Name</th>
          <th>Department Short Name</th>
          <th>Department ID</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in departments" :key="i">
          <td>{{ item.name }}</td>
          <td>{{ item.short_name }}</td>
          <td>{{ item.code }}</td>
          <td>{{ item.status }}</td>
          <td style="cursor: pointer">
            <span>View</span>
            /
            <span @click="goToEdit({...item})">Edit</span>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!departments.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DepartmentListingTable',
  props: {
    departments: [],
  },
  methods: {
    goToEdit(data) {
      this.$emit('onEdit', data);
    },
  }
}
</script>
